/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, {useContext} from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import {GlobalStateContext} from '../context/GlobalContextProvider'
import flex from '../styles/flexbox.module.css'

function Bio({author}) {
  const state = useContext(GlobalStateContext)
  const {blog, blogBoss} = state.language
  console.log('author: ', author);
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { writer, writer2, social } = data.site.siteMetadata
        return (
          <Container className={`${flex.container} ${flex.alignCenter}`}>
            <Image
              fixed={author==="Cleci Leão" ? data.avatar.childImageSharp.fixed : author==="Roberto Badra" ? data.avatar2.childImageSharp.fixed : data.avatar.childImageSharp.fixed}
              alt={author==="Cleci Leão" ? writer : author==="Roberto Badra" ? writer2 : writer}
              style={{
                marginRight: 15,
                marginBottom: 0,
                minWidth: 20,
                width: 30,
                height: 30,
                borderRadius: `100%`,
              }}
              imgStyle={{
                borderRadius: `50%`,
              }}
            />
            <small>
              {
                author==="Cleci Leão" ?
                  blog.map((item, index)=> index===0 ? item : (<a href={`https://twitter.com/${social.twitter}`}>&nbsp;{item}</a>))
                :
                  author==="Roberto Badra" ? 
                    blogBoss.map((item, index)=> index===0 ? 
                      item 
                    : 
                      (<a href={`https://twitter.com/${social.twitter}`}>&nbsp;{item}</a>))
                  :
                  blog.map((item, index)=> index===0 ? item : (<a href={`https://twitter.com/${social.twitter}`}>&nbsp;{item}</a>))    
              }
            </small>
          </Container>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    avatar2: file(absolutePath: { regex: "/profile-pic-boss.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }    
    site {
      siteMetadata {
        writer,
        writer2,
        social {
          twitter
        }
      }
    }
  }
`

const Container = styled.div`
  display: flex;
`

export default Bio

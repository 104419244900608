import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Bio from "../components/bio"
import LayoutMaster from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import {SmallSection} from "../components/sections"
import Share from '../components/share'
import Img from 'gatsby-image';

import section from '../styles/sections.module.css'
import flex from '../styles/flexbox.module.css'
import '../styles/blog.css'

const BlogPostTemplate = ({data, location, pageContext})=> {
    const post = data.mdx
    const siteTitle = data.site.siteMetadata.title
    const { previous, next } = pageContext
    const {
        frontmatter: { title, description, tags, author },
        fields: { slug },
    } = post;

    const { 
      site: {
        siteMetadata: { siteUrl, social: {twitter} },
    }} = data

    

    return (
      <LayoutMaster location={location} title={siteTitle}>

        <SEO
          title={title}
          description={description || post.excerpt}
          url={`${siteUrl}/news/${slug}`}
          image={post.frontmatter.featuredImage.childImageSharp.sizes.src}
          keywords={tags || [`qzela`, `tecnologia`, `app`, `zeladoria`, `urbana`]}
          // meta={[description, title]}
        />
        <meta property="og:image" content="http://euro-travel-example.com/thumbnail.jpg" />
        <SmallSection className={`${section.light} blogPost`}>
          <h2>{post.frontmatter.title}</h2>
          <h4>{description}</h4>
          <div className={`${flex.container} ${flex.justifyCenter} ${flex.spacing} ${flex.alignCenter} page-heading`}>
            <small>
              {post.frontmatter.date}
            </small>
            <div className={`${flex.container} ${flex.justifySpaceAround} ${flex.spacing} ${flex.alignCenter} ${flex.directionRow}`}>
              <Share 
                socialConfig={{
                  twitterHandle: twitter,
                  config: {
                    url: `${siteUrl}/news/${slug}`,
                    title,
                  },
                }}
              />
            </div>
            <Bio author={author} />
          </div>
          {post.frontmatter.featuredImage && <div className={`${section.coverContainer}`}><Img sizes={post.frontmatter.featuredImage.childImageSharp.sizes} /></div>}
          <MDXRenderer>{post.body}</MDXRenderer>
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={`news${previous.fields.slug}`} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={`news${next.fields.slug}`} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </SmallSection>
      </LayoutMaster>
    )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        description
        siteUrl
        author
        social{
          twitter
        }
        image
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        author
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 630) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
      fields {
        slug
      }      
    }
  }
`

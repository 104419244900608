import React from 'react';
import PropTypes from 'prop-types';
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	EmailShareButton,
	TelegramShareButton
} from 'react-share';
import {Facebook, Twitter, Whatsapp, Linkedin, Telegram, Mail} from './icons'

import '../styles/blog.css'

const Share = ({ socialConfig }) =>{
    return(
		<>
			<FacebookShareButton url={socialConfig.config.url} title={socialConfig.config.title}>
				<span className="icon">
					<Facebook className={`socialIcons`} width="20" height="20" />
				</span>
			</FacebookShareButton>
			<TwitterShareButton url={socialConfig.config.url} title={socialConfig.config.title} via={socialConfig.twitterHandle.split('@').join('')} >
				<span className="icon">
					<Twitter className={`socialIcons`} width="20" height="20" />
				</span>
			</TwitterShareButton>
			<LinkedinShareButton url={socialConfig.config.url} title={socialConfig.config.title}>
				<span className="icon">
					<Linkedin className={`socialIcons`} width="20" height="20" />
				</span>
			</LinkedinShareButton>
			<WhatsappShareButton url={socialConfig.config.url} title={socialConfig.config.title} >
				<span className="icon">
					<Whatsapp className={`socialIcons`} width="20" height="20" />
				</span>
			</WhatsappShareButton>
			<TelegramShareButton url={socialConfig.config.url} title={socialConfig.config.title} >
				<span className="icon">
					<Telegram className={`socialIcons`} width="20" height="20" />
				</span>
			</TelegramShareButton>
			<EmailShareButton url={socialConfig.config.url} title={socialConfig.config.title}>
				<span className="icon">
					<Mail className={`socialIcons`} width="20" height="20" />
				</span>
			</EmailShareButton>
		</>
    )
}

Share.propTypes = {
	socialConfig: PropTypes.shape({
		twitterHandle: PropTypes.string.isRequired,
		config: PropTypes.shape({
			url: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
		}),
	}).isRequired,
};

export default Share;